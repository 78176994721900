import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';

import { CHANNEL, inspirationPageSlugs } from '@boss/constants/b2b-b2c';
import { content as contentService } from '@boss/services';
import { Environment } from '@boss/types/b2b-b2c';

import { SEO } from '../../components';
import { IS_BUILD, PageTypes } from '../../constants';
import { InspirationOverviewPage } from '../../containers';
import Layout from '../../layout/default';
import getServerSideTranslations from '../../utils/getServerSideTranslations';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';
import { getMappedNavigation } from '../../utils/navigation';

export const config = {
  maxDuration: 30,
};

export const getStaticProps = async ({ preview, locale }: GetStaticPropsContext) => {
  if (!locale || locale === 'default') {
    return {
      notFound: true,
    };
  }

  const contentfulLocale = mapNextToContentfulLocale(locale);

  const [navigation, overviewPage] = await Promise.all([
    getMappedNavigation(contentfulLocale, !!preview),
    contentService.getOverviewPage({
      locale: contentfulLocale,
      channel: CHANNEL,
      type: 'Inspiration overview',
      batchMode: IS_BUILD,
      preview: !!preview,
    }),
  ]);

  if (!navigation || !overviewPage) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      pageType: PageTypes.INSPIRATION_OVERVIEW,
      pageData: { overviewPage },
      locale,
      preview: !!preview,
      navigation,
      environment: process.env.CONTENTFUL_ENVIRONMENT as Environment,
      ...(await getServerSideTranslations(locale, ['common', 'inspiration', 'forms'])),
    },
  };
};

export const Index = ({
  pageData,
  navigation,
  preview,
  environment,
  locale,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { t } = useTranslation();
  const { overviewPage } = pageData;

  const pageTitle = t('inspirationImagesLabel', { ns: 'inspiration' });
  const pageDescription = pageTitle; // TODO: Update with final value once this is known
  const crumbles = [{ href: inspirationPageSlugs[locale], label: pageTitle }];

  return (
    <SEO
      breadcrumbs={crumbles}
      description={overviewPage?.metaDescription ?? pageDescription}
      imageSrc={overviewPage?.ogImage?.image?.file?.url}
      localizedSlugs={inspirationPageSlugs}
      navigation={navigation}
      noFollow={overviewPage?.noFollow}
      noIndex={overviewPage?.noIndex}
      title={overviewPage?.pageTitle ?? pageTitle}
    >
      <Layout
        crumbles={crumbles}
        environment={environment}
        localizedSlugs={inspirationPageSlugs}
        navigation={navigation}
        preview={preview}
      >
        <InspirationOverviewPage locale={locale} page={overviewPage} />
      </Layout>
    </SEO>
  );
};

export default Index;
